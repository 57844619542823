export const opernhauser = [
  {
    name: 'Theater Bremerhaven',
    link: 'https://stadttheaterbremerhaven.de/',
  },
  {
    name: 'Theater Flensburg',
    link: 'https://www.sh-landestheater.de/',
  },
  {
    name: 'Theater Bielefeld',
    link: 'https://theater-bielefeld.de/',
  },
  {
    name: 'Theater Lübeck',
    link: 'https://www.theaterluebeck.de/',
  },
  {
    name: 'Theater Linz',
    link: 'https://www.landestheater-linz.at/',
  },
  {
    name: 'Deutsche Oper am Rhein',
    link: 'https://operamrhein.de/',
  },
  {
    name: 'Theater Klagenfurth',
    link: 'https://www.stadttheater-klagenfurt.at/',
  },
  {
    name: 'Arnold Schönberg Center Wien',
    link: 'https://www.schoenberg.at/',
  },
  {
    name: 'Konzerthaus Dortmund',
    link: 'https://www.konzerthaus-dortmund.de/de/',
  },
  {
    name: 'Gärtnerplatztheater',
    link: 'https://www.gaertnerplatztheater.de/de/',
  },
  {
    name: 'Hamburger Staatsoper',
    link: 'https://www.theaterakademie.de/',
  },
  {
    name: 'Festspielhaus Baden–Baden',
    link: 'https://www.festspielhaus.de/en/',
  },
  {
    name: 'Komische Oper berlin',
    link: 'https://www.komische-oper-berlin.de/',
  },
];

export const rollen = [
  'Lulu',
  'Gräfin Geschwitz',
  'A.Berg',
  'Hänsel und Gretel: Mutter und Hexe',
  'E.Humperdinck',
];

export const unterricht = `
      Ihre Arbeit an Stimme und Körper basiert auf ihrer persönlichen und
      künstlerischen Erfahrung, die sie mit Einfühlsamkeit, spielerischer
      Leichtigkeit und gutem Gespür für Menschen weitergibt. Entkrampfen und
      Neuentdecken der Stimme und gleichzeitig positives, Kraftspendendes
      Erleben Überwindung von posttraumatischen Belastungsstörungen
      Transformatorische Prozesse Verwirklichung neuer Lebensziele. Unterricht
      in einem Münchner Studio.
`;

export const vita = `
      Die Opernsängerin Michaela Lucas stammt aus München. Sie wurde in der
      Hochschule München bei Frau Prof. Margot Gerdes ausgebildet und hat mit
      namhaften Regisseuren zusammengearbeitet, so wie Ruth Berghaus, Nicolaus
      Lehnhoff, Ruth Berhaus, Harry Kupfer, Olivier Tambosi, Barrie Kosky.
`;
